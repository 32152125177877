@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.tab-curved {
  position: relative;
}

.tab-curved::before,
.tab-curved::after {
  content: '';
  position: absolute;
  bottom: -10px; /* Adjust based on the desired curve size */
  width: 20px;
  height: 20px;
  background-color: #5152fd;
  border-radius: 50%;
  z-index: -1;
}

.tab-curved::before {
  left: -10px;
}

.tab-curved::after {
  right: -10px;
}

